<template>
  <div class="d-flex flex-column align-center justify-center">
    <v-card
      class="d-flex flex-column align-center mx-auto my-4"
      :disabled="state.isLoading"
      max-width="500"
    >
      <v-card-title>Reset Password</v-card-title>

      <v-form @submit.prevent="resetPassword">
        <v-card-text>
          <v-text-field
            v-model="state.email"
            label="Email"
            type="email"
            outlined
            dense
            hide-details=""
          ></v-text-field>
        </v-card-text>

        <v-card-text>
          <v-text-field
            v-model="state.password"
            label="New Password"
            type="password"
            outlined
            dense
            hide-details=""
          ></v-text-field>
        </v-card-text>

        <v-card-text>
          <v-btn type="submit" block>Confirm</v-btn>
        </v-card-text>
      </v-form>

      <v-card-text class="d-flex flex-column align-center" v-show="state.success">
        <v-alert type="success" dense>Password reset successfully</v-alert>

        <v-btn @click="navigateToLogin" class="mx-auto" color="success">Login</v-btn>
      </v-card-text>

      <v-card-text v-show="state.error">
        <v-alert type="warning" dense>{{ state.error }}</v-alert>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "@axios";
import { reactive } from "@vue/composition-api";
import { useRouter } from "@/composables/router";

export default {
  name: "PasswordReset",
  setup() {
    const { currRouteParams } = useRouter();

    const state = reactive({
      isLoading: false,
      error: "",
      success: false,
      email: "",
      password: "",
    });

    async function resetPassword() {
      if (!state.email || !state.password) return;

      state.isLoading = true;
      state.error = "";

      try {
        await axios.post("/reset-password", {
          email: state.email,
          password: state.password,
          token: currRouteParams.value.token,
        });
        state.success = true;
      } catch (err) {
        state.error = err.response.data.message;
      } finally {
        state.isLoading = false;
      }
    }

    function navigateToLogin() {
      this.$router.push({ name: "auth-login" });
    }

    return {
      state,
      resetPassword,
      navigateToLogin,
    };
  },
};
</script>

<style></style>
